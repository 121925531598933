export const LIBRARY_DETAIL_LOAD_REQUEST = 'LIBRARY_DETAIL_LOAD_REQUEST';
export const LIBRARY_DETAIL_LOAD_SUCCESS = 'LIBRARY_DETAIL_LOAD_SUCCESS';
export const LIBRARY_DETAIL_LOAD_FAILURE = 'LIBRARY_DETAIL_LOAD_FAILURE';


export const LIBRARY_SAVE_REQUEST = 'LIBRARY_SAVE_REQUEST';
export const LIBRARY_SAVE_SUCCESS = 'LIBRARY_SAVE_SUCCESS';
export const LIBRARY_SAVE_FAILURE = 'LIBRARY_SAVE_FAILURE';

export const LIBRARY_UPDATE_REQUEST = 'LIBRARY_UPDATE_REQUEST';
export const LIBRARY_UPDATE_SUCCESS = 'LIBRARY_UPDATE_SUCCESS';
export const LIBRARY_UPDATE_FAILURE = 'LIBRARY_UPDATE_FAILURE';

export const SEARCH_RECORDS_LOAD_REQUEST = 'SEARCH_RECORDS_LOAD_REQUEST';
export const SEARCH_RECORDS_LOAD_SUCCESS = 'SEARCH_RECORDS_LOAD_SUCCESS';
export const SEARCH_RECORDS_LOAD_FAILURE = 'SEARCH_RECORDS_LOAD_FAILURE';

export const UPDATE_LIBRARY = 'UPDATE_LIBRARY';
