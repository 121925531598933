export const SHARINGGROUP_DETAIL_LOAD_REQUEST = 'SHARINGGROUP_DETAIL_LOAD_REQUEST';
export const SHARINGGROUP_DETAIL_LOAD_SUCCESS = 'SHARINGGROUP_DETAIL_LOAD_SUCCESS';
export const SHARINGGROUP_DETAIL_LOAD_FAILURE = 'SHARINGGROUP_DETAIL_LOAD_FAILURE';

export const SHARINGGROUP_LIST_LOAD_REQUEST = 'SHARINGGROUP_LIST_LOAD_REQUEST';
export const SHARINGGROUP_LIST_LOAD_SUCCESS = 'SHARINGGROUP_LIST_LOAD_SUCCESS';
export const SHARINGGROUP_LIST_LOAD_FAILURE = 'SHARINGGROUP_LIST_LOAD_FAILURE';

export const SHARINGGROUP_SAVE_REQUEST = 'SHARINGGROUP_SAVE_REQUEST';
export const SHARINGGROUP_SAVE_SUCCESS = 'SHARINGGROUP_SAVE_SUCCESS';
export const SHARINGGROUP_SAVE_FAILURE = 'SHARINGGROUP_SAVE_FAILURE';

export const SHARINGGROUP_UPDATE_REQUEST = 'SHARINGGROUP_UPDATE_REQUEST';
export const SHARINGGROUP_UPDATE_SUCCESS = 'SHARINGGROUP_UPDATE_SUCCESS';
export const SHARINGGROUP_UPDATE_FAILURE = 'SHARINGGROUP_UPDATE_FAILURE';

export const GROUP_LIST_LOAD_NOT_FOUND = "GROUP_LIST_LOAD_NOT_FOUND";
export const FETCH_SHARED_LIST_SUCCESS = "FETCH_SHARED_LIST_SUCCESS";

