// import { applyMiddleware, combineReducers, createStore } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
// import thunk from 'redux-thunk';
// import authReducer from './auth/authReducer';
// import libraryReducer from './library/libraryReducer';
// import usersReducer from './users/usersReducer';
// import FtpReducer from './ftp/ftpReducer';
// import GroupsReducer from './groups/GroupsReducer';
// import SharingGroupsReducer from './sharingGroup/SharingGroupsReducer';


// const rootReducer = combineReducers({

//     auth: authReducer,
//     library: libraryReducer,
//     users: usersReducer,
//     ftp: FtpReducer,
//     groups: GroupsReducer,
//     share: SharingGroupsReducer

// });
// const middleware = [thunk];
// if (process.env.NODE_ENV !== `production`) {
//     middleware.push(logger);
// }
// const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(...middleware))
// );
// export default store;


import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import authReducer from './auth/authReducer';
import libraryReducer from './library/libraryReducer';
import usersReducer from './users/usersReducer';
import FtpReducer from './ftp/ftpReducer';
import GroupsReducer from './groups/GroupsReducer';
import SharingGroupsReducer from './sharingGroup/SharingGroupsReducer';
import loaderReducer from './loader/loaderReducer';

const loadAuthStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('authState');
        if (serializedState === null) return undefined;
        return { auth: JSON.parse(serializedState) };
    } catch (e) {
        console.warn('Could not load state', e);
        return undefined;
    }
};

const rootReducer = combineReducers({
    auth: authReducer,
    library: libraryReducer,
    users: usersReducer,
    ftp: FtpReducer,
    groups: GroupsReducer,
    share: SharingGroupsReducer,
    loader: loaderReducer,
});

const middleware = [thunk];
if (process.env.NODE_ENV !== `production`) {
    middleware.push(logger);
}

const persistedState = loadAuthStateFromLocalStorage();

const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
