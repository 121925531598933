export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

export const AUTH_LOGIN_DETAILS_REQUEST = 'AUTH_LOGIN_DETAILS_REQUEST';
export const AUTH_LOGIN_DETAILS_SUCCESS = 'AUTH_LOGIN_DETAILS_SUCCESS';
export const AUTH_LOGIN_DETAILS_FAILURE = 'AUTH_LOGIN_DETAILS_FAILURE';


export const AUTH_UPDATE_PASSWORD_REQUEST = 'AUTH_UPDATE_PASSWORD_REQUEST';
export const AUTH_UPDATE_PASSWORD_SUCCESS = 'AUTH_UPDATE_PASSWORD_SUCCESS';
export const AUTH_UPDATE_PASSWORD_FAILURE = 'AUTH_UPDATE_PASSWORD_FAILURE';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

