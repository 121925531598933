import {
  LIBRARY_DETAIL_LOAD_FAILURE,
  LIBRARY_DETAIL_LOAD_REQUEST,
  LIBRARY_DETAIL_LOAD_SUCCESS,
  LIBRARY_SAVE_FAILURE,
  LIBRARY_SAVE_REQUEST,
  LIBRARY_SAVE_SUCCESS,
  LIBRARY_UPDATE_FAILURE,
  LIBRARY_UPDATE_REQUEST,
  LIBRARY_UPDATE_SUCCESS,
  UPDATE_LIBRARY
} from './libraryTypes';

const initialState = {
  loading: false,
  data: [],
  errors: [],
  detailLoading: true,
  detailData: null,
  libraries: [],
  saveLoading: false,
  updateLoading: false,

};

const libraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_DETAIL_LOAD_REQUEST:
      return {
        ...state,
        detailErrors: [],
        detailData: null,
        detailLoading: true,
      };
    case LIBRARY_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailData: action.payload,
      };
    case LIBRARY_DETAIL_LOAD_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailErrors: action.payload,
      };
    case LIBRARY_SAVE_REQUEST:
      return {
        ...state,
        saveErrors: [],
        saveLoading: true,
      };
    case LIBRARY_SAVE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case LIBRARY_SAVE_FAILURE:
      return {
        ...state,
        saveLoading: false,
        saveErrors: action.payload,
      };
    case LIBRARY_UPDATE_REQUEST:
      return {
        ...state,
        updateErrors: [],
        updateLoading: true,
      };
    case LIBRARY_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case LIBRARY_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateErrors: action.payload,
      };
    case UPDATE_LIBRARY:
      return {
        ...state,
        detailData: {
          data: action.payload.data,
          totalRecords: action.payload.totalRecords
        }, // // Update the state with new data
      };


    default:
      return state;
  }
};

export default libraryReducer;
