export const USER_DETAIL_LOAD_REQUEST = 'USER_DETAIL_LOAD_REQUEST';
export const USER_DETAIL_LOAD_SUCCESS = 'USER_DETAIL_LOAD_SUCCESS';
export const USER_DETAIL_LOAD_FAILURE = 'USER_DETAIL_LOAD_FAILURE';


export const ADMIN_LOGS_DETAIL_LOAD_REQUEST = 'ADMIN_LOGS_DETAIL_LOAD_REQUEST';
export const ADMIN_LOGS_DETAIL_LOAD_SUCCESS = 'ADMIN_LOGS_DETAIL_LOAD_SUCCESS';
export const ADMIN_LOGS_DETAIL_LOAD_FAILURE = 'ADMIN_LOGS_DETAIL_LOAD_FAILURE';

export const USER_SAVE_REQUEST = 'USER_SAVE_REQUEST';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_FAILURE = 'USER_SAVE_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
