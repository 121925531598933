import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, FormGroup, FormText, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
// import { useDispatch } from "react-redux";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import axios from 'axios';
const Dashboard = (args) => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const addform = () => {
        navigate('/addform');
    };
    // const [modal, setModal] = useState(false);
    // const toggle = () => setModal(!modal);
    // const user = JSON.parse(localStorage.getItem('user'));

    const [fileToUpload, setFileToUpload] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [imgUploading, setImgUploading] = useState(false);
    const [uploadStatuses, setUploadStatuses] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [errorArray, setErrorArray] = useState([]);
    const [totalInsertedVal, setTotalInsertedVal] = useState(0);
    const [duplicate, setDuplicate] = useState(0);
    const [loading, setLoading] = useState(false);

    function postFile(fileToUpload) {
        const userobject = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append("userid", userobject['UserID']);
        formData.append("useremail", userobject['UserEmail']);
        console.log(formData, "formdataaa")
        // Make a POST request using axios
        return axios.post(process.env.REACT_APP_API_GATEWAY + '/book_excel_upload', formData);
    }



    const updatepostfile = (lang, index) => {
        console.log(lang, "updatedata");
        const userobject = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();
        formData.append("data", JSON.stringify(lang));
        formData.append("userid", userobject['UserID']);
        formData.append("useremail", userobject['UserEmail']);

        axios.post(process.env.REACT_APP_API_GATEWAY + '/updateExcelupload', formData)
            .then(Response => {
                console.log("Response from server:", Response.data);
                // Update uploadStatuses state
                setUploadStatuses(prevStatuses => {
                    const updatedStatuses = [...prevStatuses];
                    console.log(Response, "upppp")
                    updatedStatuses[index] = index + 1;
                    return updatedStatuses;
                });
            })
            .catch(error => {
                // Handle error
                console.error("Error:", error);
            });
    };


    function imagepostFile(fileToUpload) {
        const userobject = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append("userid", userobject['UserID']);
        formData.append("useremail", userobject['UserEmail']);
        return axios.post(process.env.REACT_APP_API_GATEWAY + '/imageupload', formData);
    }

    const handleFileInput = (files) => {
        setFileToUpload(files[0]);
        setIsUploading(true);
    };

    const handleImageInput = (files) => {
        const image = files[0];
        var maxfilesize = 5242880;
        if (image) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imgData = e.target.result;
                const img = new Image();
                img.src = imgData;
                img.onload = function () {
                    if (img.size > maxfilesize) {
                        toastr.error('File size must be less than 5MB');
                        setImgUploading(false);
                        return;
                    }
                    // If size is acceptable, proceed with uploading or other actions
                    setFileToUpload(image);
                    setImgUploading(true);
                    // setFilename(image.name);
                };
            };
            reader.readAsDataURL(image);
        }
    };


    const uploadimageFileToActivity = () => {
        setLoading(true);
        imagepostFile(fileToUpload)
            .then(data => {
                console.log(data, "datatuimg");
                toastr.success('Successfully Image saved data');
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const uploadFileToActivity = () => {
        setLoading(true);
        postFile(fileToUpload)
            .then(data => {
                const duplicateArray = data.data.duplicate;
                console.log(duplicateArray, "Duplicate Array");
                setShowForm(true);
                setErrorArray(data.data.errored);
                setTotalInsertedVal(data.data.totalinsertedval);
                setDuplicate(duplicateArray);
                toastr.success('Successfully saved data');
                setFileToUpload(null);
                setIsUploading(false);
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    return (

        <div>
            <div>
                {loading && <Spinner />}
            </div>
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col sm="6" lg="6" xl="5" xxl="6">
                    <Card style={{ boxshadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                        <CardBody>
                            <CardTitle style={{ justifyContent: "center", display: "flex", marginBottom: "15px", padding: "8px", borderRadius: "5px", background: "#efd594" }} tag="h4">ONIX XML Generator</CardTitle>
                            <CardSubtitle className="text-muted pb-0 mb-2" tag="h6">
                                <a href={require('../assets/onixtemplate.xlsx')} download="onixtemplate.xlsx">
                                    <i className="bi bi-file-earmark-arrow-down-fill" /> Download ONIX Excel Template
                                </a>
                            </CardSubtitle>

                            <FormGroup>
                                <Input type="file" onChange={(e) => handleFileInput(e.target.files)} accept=".xlsx, .xls" />
                                <FormText>
                                    Supported file format's .xls & .xlsx.
                                </FormText>
                            </FormGroup>
                            <div style={{ display: "flex", justifyContent: "center", padding: "18px" }}>
                                <Button
                                    color="primary"
                                    style={{ marginRight: "7px" }}
                                    onClick={uploadFileToActivity}
                                    disabled={!isUploading}
                                >
                                    Upload
                                </Button>

                                <Button onClick={addform}
                                    color="primary"
                                    style={{ marginLeft: "7px", width: "40%" }}
                                    outline
                                >
                                    Enter Manualy
                                </Button>
                            </div>

                            <div className="mt-4">
                                <CardTitle tag="h6" style={{ fontWeight: "500" }}>Upload images to server</CardTitle>
                                <CardSubtitle className="text-muted" tag="h6" style={{ fontSize: "11px" }}>
                                    Note* Image file name must be isb13 and FrontCoverImage--(isb13-ISBN_MAIN) also for BackCoverImage--(isb13-ISBN_BACK).
                                    {/* Note* Image file names must be isb13-ISBN_MAIN for the front cover and isb13-ISBN_BACK for the back cover. */}
                                </CardSubtitle>
                                <FormGroup>
                                    <Input
                                        className="mt-1"
                                        id="exampleFile"
                                        onChange={(e) => handleImageInput(e.target.files)}
                                        type="file"
                                        accept=".jpg,.png"
                                    />
                                </FormGroup>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        color="primary"
                                        style={{ marginRight: "7px" }}
                                        disabled={!imgUploading}
                                        onClick={uploadimageFileToActivity}
                                    >
                                        Upload Image
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <div>
                <Modal isOpen={showForm} toggle={() => setShowForm(false)}>
                    <ModalHeader toggle={() => setShowForm(false)}>ONIX XML Generator</ModalHeader>
                    <ModalBody>
                        <h6 style={{ color: "black", fontWeight: "500" }}>Total inserted values count: {totalInsertedVal}</h6>
                        <h6 style={{ color: "black", fontWeight: "500" }}>Invalid records count: {errorArray?.length}</h6>
                        {Array.isArray(errorArray) && errorArray.map((lang, i) => (
                            <div key={i} >
                                <h6 className="bg-secondary p-1" style={{ color: "white", fontSize: "13px" }}>{i + 1}) Record ({lang}) is invalid</h6>
                            </div>
                        ))}
                        <h6 className="mt-2" style={{ color: "black", fontWeight: "500" }}>Duplicate records count: {duplicate?.length}</h6>
                        {console.log(duplicate, "dupicatee")}

                        {Array.isArray(duplicate) && duplicate.map((lang, i) => (
                            <div className="bg-secondary p-1 m-1" key={i} style={{ display: "flex", justifyContent: "space-between" }}>
                                <h6 style={{ color: "white", fontSize: "13px", width: "100%" }}>{i + 1}) Record ({lang.isbn13}) is duplicate</h6>
                                {!uploadStatuses[i] ? (
                                    <Button color="primary" key={i} className="p-1" onClick={() => updatepostfile(lang, i)}>
                                        Update
                                    </Button>
                                ) : (
                                    <Button key={i} disabled color="success" className="p-1">
                                        Updated
                                    </Button>
                                )}
                            </div>
                        ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setShowForm(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>


            {/* <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add User</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="email">User Name </Label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="bi bi-envelope-at-fill fs-5"></i>
                                </span>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="UserEmail"
                                    id="UserEmail"
                                    placeholder="Enter your email"
                                    value={formData.UserEmail}
                                    onChange={handleChange}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="bi bi-person-fill fs-5"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder="Enter Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="role">Enter Role*</Label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="bi bi-person-rolodex fs-5"></i>
                                </span>
                                <select
                                    className="form-select"
                                    name="role"
                                    id="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                >
                                    <option value="admin">Admin</option>
                                    <option value="staff">Staff</option>

                                </select>
                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Add
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal> */}
        </div >



    );
};

export default Dashboard;
