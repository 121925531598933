// components/Loader.js
import React from 'react';
import { useSelector } from 'react-redux';
import './loader.scss'; // Include your loader styles here

const Loader = () => {
  const loading = useSelector((state) => state.loader.loading);

  // console.log('loader', loading)

  if (!loading) return null;

  return (
    <div className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
