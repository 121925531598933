
import {
	AUTH_LOGIN_FAILURE,
	AUTH_LOGIN_REQUEST,
	AUTH_LOGIN_SUCCESS,
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGIN_DETAILS_FAILURE,
	AUTH_LOGIN_DETAILS_REQUEST,
	AUTH_LOGIN_DETAILS_SUCCESS,
} from './authTypes';

const initialState = {
	loading: false,
	user: null,
	isAuthenticated: false,
	errors: [],
	updatePasswordLoading: false,
	updatePasswordErrors: [],
};

const saveStateToLocalStorage = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('authState', serializedState);
	} catch (e) {
		console.warn('Could not save state', e);
	}
};

const loadStateFromLocalStorage = () => {
	try {
		const serializedState = localStorage.getItem('authState');
		if (serializedState === null) return initialState;
		return JSON.parse(serializedState);
	} catch (e) {
		console.warn('Could not load state', e);
		return initialState;
	}
};

const authReducer = (state = loadStateFromLocalStorage(), action) => {
	let newState;
	switch (action.type) {
		case AUTH_LOGIN_REQUEST:
			newState = {
				...state,
				...initialState,
				loading: true,
			};
			break;
		case AUTH_LOGIN_SUCCESS:
			newState = {
				...state,
				loading: false,
				isAuthenticated: true,
				user: action.payload.result,
			};
			break;
		case AUTH_LOGIN_FAILURE:
			newState = {
				...state,
				loading: false,
				errors: action.payload,
			};
			break;
		case AUTH_LOGIN_DETAILS_REQUEST:
			newState = {
				...state,
				...initialState,
				loading: true,
			};
			break;
		case AUTH_LOGIN_DETAILS_SUCCESS:
			newState = {
				...state,
				loading: false,
				user: action.payload,
			};
			break;
		case AUTH_LOGIN_DETAILS_FAILURE:
			newState = {
				...state,
				loading: false,
				errors: action.payload,
			};
			break;
		case AUTH_LOGOUT_SUCCESS:
			newState = {
				...state,
				...initialState,
			};
			break;
		default:
			newState = state;
	}
	saveStateToLocalStorage(newState);
	return newState;
};


export default authReducer;
