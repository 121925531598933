import {
  GROUPS_DETAIL_LOAD_FAILURE,
  GROUPS_DETAIL_LOAD_REQUEST,
  GROUPS_DETAIL_LOAD_SUCCESS,
  GROUPLIST_LOAD_FAILURE,
  GROUPLIST_LOAD_REQUEST,
  GROUPLIST_LOAD_SUCCESS,
  GROUPS_SAVE_FAILURE,
  GROUPS_SAVE_REQUEST,
  GROUPS_SAVE_SUCCESS,
  GROUPLIST_SAVE_FAILURE,
  GROUPLIST_SAVE_REQUEST,
  GROUPLIST_SAVE_SUCCESS,
  GROUPS_UPDATE_FAILURE,
  GROUPS_UPDATE_REQUEST,
  GROUPS_UPDATE_SUCCESS,
  GROUP_LIST_LOAD_NOT_FOUND

} from './GroupsTypes';

const initialState = {
  loading: false,
  errors: [],
  detailLoading: true,
  detailData: null,
  data: [],
  saveLoading: false,
  updateLoading: false,


};

const GroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUPS_DETAIL_LOAD_REQUEST:
      return {
        ...state,
        detailErrors: [],
        detailData: null,
        detailLoading: true,
      };
    case GROUPS_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailData: action.payload,
      };
    case GROUPS_DETAIL_LOAD_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailErrors: action.payload,
      };
    case GROUPLIST_LOAD_REQUEST:
      return {
        ...state,
        detailErrors: [],
        detailData: null,
        detailLoading: true,
      };
    case GROUPLIST_LOAD_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailData: action.payload,
      };
    case GROUPLIST_LOAD_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailErrors: action.payload,
      };
    case GROUP_LIST_LOAD_NOT_FOUND:
      return {
        ...state,
        loading: false,
        data: [], // Reset the data
      };
    case GROUPS_SAVE_REQUEST:
      return {
        ...state,
        saveErrors: [],
        saveLoading: true,
      };
    case GROUPS_SAVE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case GROUPS_SAVE_FAILURE:
      return {
        ...state,
        saveLoading: false,
        saveErrors: action.payload,
      };
    case GROUPLIST_SAVE_REQUEST:
      return {
        ...state,
        saveErrors: [],
        saveLoading: true,
      };
    case GROUPLIST_SAVE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case GROUPLIST_SAVE_FAILURE:
      return {
        ...state,
        saveLoading: false,
        saveErrors: action.payload,
      };
    case GROUPS_UPDATE_REQUEST:
      return {
        ...state,
        updateErrors: [],
        updateLoading: true,
      };
    case GROUPS_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case GROUPS_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateErrors: action.payload,
      };


    default:
      return state;
  }
};

export default GroupsReducer;
