import {
    FTP_DETAIL_LOAD_FAILURE,
    FTP_DETAIL_LOAD_REQUEST,
    FTP_DETAIL_LOAD_SUCCESS,
    FTP_SAVE_FAILURE,
    FTP_SAVE_REQUEST,
    FTP_SAVE_SUCCESS,
    FTP_UPDATE_FAILURE,
    FTP_UPDATE_REQUEST,
    FTP_UPDATE_SUCCESS,
} from './ftpTypes';

const initialState = {
    loading: false,
    data: [],
    errors: [],
    detailLoading: true,
    detailData: null,
    detailErrors: [],
    saveLoading: false,
    saveErrors: [],
    updateLoading: false,
    updateErrors: [],

};

const FtpReducer = (state = initialState, action) => {
    switch (action.type) {
        case FTP_DETAIL_LOAD_REQUEST:
            return {
                ...state,
                detailErrors: [],
                detailData: null,
                detailLoading: true,
            };
        case FTP_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                detailData: action.payload,
            };
        case FTP_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                detailLoading: false,
                detailErrors: action.payload,
            };
        case FTP_SAVE_REQUEST:
            return {
                ...state,
                saveErrors: [],
                saveLoading: true,
            };
        case FTP_SAVE_SUCCESS:
            return {
                ...state,
                saveLoading: false,
            };
        case FTP_SAVE_FAILURE:
            return {
                ...state,
                saveLoading: false,
                saveErrors: action.payload,
            };
        case FTP_UPDATE_REQUEST:
            return {
                ...state,
                updateErrors: [],
                updateLoading: true,
            };
        case FTP_UPDATE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case FTP_UPDATE_FAILURE:
            return {
                ...state,
                updateLoading: false,
                updateErrors: action.payload,
            };

        default:
            return state;
    }
};

export default FtpReducer;
