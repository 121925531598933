export const GROUPS_DETAIL_LOAD_REQUEST = 'GROUPS_DETAIL_LOAD_REQUEST';
export const GROUPS_DETAIL_LOAD_SUCCESS = 'GROUPS_DETAIL_LOAD_SUCCESS';
export const GROUPS_DETAIL_LOAD_FAILURE = 'GROUPS_DETAIL_LOAD_FAILURE';

export const GROUPLIST_LOAD_REQUEST = 'GROUPLIST_LOAD_REQUEST';
export const GROUPLIST_LOAD_SUCCESS = 'GROUPLIST_LOAD_SUCCESS';
export const GROUPLIST_LOAD_FAILURE = 'GROUPLIST_LOAD_FAILURE';


export const GROUPS_SAVE_REQUEST = 'GROUPS_SAVE_REQUEST';
export const GROUPS_SAVE_SUCCESS = 'GROUPS_SAVE_SUCCESS';
export const GROUPS_SAVE_FAILURE = 'GROUPS_SAVE_FAILURE';

export const GROUPLIST_SAVE_REQUEST = 'GROUPLIST_SAVE_REQUEST';
export const GROUPLIST_SAVE_SUCCESS = 'GROUPLIST_SAVE_SUCCESS';
export const GROUPLIST_SAVE_FAILURE = 'GROUPLIST_SAVE_FAILURE';

export const GROUPS_UPDATE_REQUEST = 'GROUPS_UPDATE_REQUEST';
export const GROUPS_UPDATE_SUCCESS = 'GROUPS_UPDATE_SUCCESS';
export const GROUPS_UPDATE_FAILURE = 'GROUPS_UPDATE_FAILURE';

export const GROUP_LIST_LOAD_NOT_FOUND = "GROUP_LIST_LOAD_NOT_FOUND";

