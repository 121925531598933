

export const FTP_DETAIL_LOAD_REQUEST = 'FTP_DETAIL_LOAD_REQUEST';
export const FTP_DETAIL_LOAD_SUCCESS = 'FTP_DETAIL_LOAD_SUCCESS';
export const FTP_DETAIL_LOAD_FAILURE = 'FTP_DETAIL_LOAD_FAILURE';

export const FTP_SAVE_REQUEST = 'FTP_SAVE_REQUEST';
export const FTP_SAVE_SUCCESS = 'FTP_SAVE_SUCCESS';
export const FTP_SAVE_FAILURE = 'FTP_SAVE_FAILURE';

export const FTP_UPDATE_REQUEST = 'FTP_UPDATE_REQUEST';
export const FTP_UPDATE_SUCCESS = 'FTP_UPDATE_SUCCESS';
export const FTP_UPDATE_FAILURE = 'FTP_UPDATE_FAILURE';
