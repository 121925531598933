import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../redux/store";

const PrivateRoute = ({ element }) => {
    // console.log(store.getState())
    const isAuthenticated = store.getState().auth.isAuthenticated;

    // console.log(isAuthenticated, "ussisauth")

    return isAuthenticated ? element : <Navigate to="/signin" />;
};

export default PrivateRoute;

