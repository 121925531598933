
import {
  SHARINGGROUP_DETAIL_LOAD_FAILURE,
  SHARINGGROUP_DETAIL_LOAD_REQUEST,
  SHARINGGROUP_DETAIL_LOAD_SUCCESS,
  SHARINGGROUP_SAVE_FAILURE,
  SHARINGGROUP_SAVE_REQUEST,
  SHARINGGROUP_SAVE_SUCCESS,
  SHARINGGROUP_UPDATE_FAILURE,
  SHARINGGROUP_UPDATE_REQUEST,
  SHARINGGROUP_UPDATE_SUCCESS,
  SHARINGGROUP_LIST_LOAD_REQUEST,
  SHARINGGROUP_LIST_LOAD_FAILURE,
  SHARINGGROUP_LIST_LOAD_SUCCESS,
  FETCH_SHARED_LIST_SUCCESS
  // SHARING_GROUP_LIST_LOAD_NOT_FOUND

} from './SharingGroupsTypes';

const initialState = {
  loading: false,
  errors: [],
  detailLoading: true,
  detailData: null,
  data: [],
  saveLoading: false,
  updateLoading: false,


};

const SharingGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHARINGGROUP_DETAIL_LOAD_REQUEST:
      return {
        ...state,
        detailErrors: [],
        detailData: null,
        detailLoading: true,
      };
    case SHARINGGROUP_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailData: action.payload,
      };
    case SHARINGGROUP_DETAIL_LOAD_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailErrors: action.payload,
      };
    case SHARINGGROUP_LIST_LOAD_REQUEST:
      return {
        ...state,
        detailErrors: [],
        detailData: null,
        detailLoading: true,
      };
    case SHARINGGROUP_LIST_LOAD_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        detailData: action.payload,
      };
    case SHARINGGROUP_LIST_LOAD_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailErrors: action.payload.data,
      };
    case FETCH_SHARED_LIST_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        data: action.payload,
      };

    case SHARINGGROUP_SAVE_REQUEST:
      return {
        ...state,
        saveErrors: [],
        saveLoading: true,
      };
    case SHARINGGROUP_SAVE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case SHARINGGROUP_SAVE_FAILURE:
      return {
        ...state,
        saveLoading: false,
        saveErrors: action.payload,
      };
    case SHARINGGROUP_UPDATE_REQUEST:
      return {
        ...state,
        updateErrors: [],
        updateLoading: true,
      };
    case SHARINGGROUP_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case SHARINGGROUP_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateErrors: action.payload,
      };


    default:
      return state;
  }
};

export default SharingGroupsReducer;
