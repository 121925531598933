// import { lazy } from "react";
// import { Navigate } from "react-router-dom";
// import Dashboard from "../compo/dashboard.js";

// import PrivateRoute from "../compo/private.js";
// /****Layouts*****/
// const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
// const Barlayout = lazy(() => import("../layouts/barlayout.js"));

// /***** Pages ****/

// const Signin = lazy(() => import("../compo/Signin.js"));
// const Library = lazy(() => import("../compo/library.js"));
// const Users = lazy(() => import("../compo/Users.js"));
// const Groups = lazy(() => import("../compo/Groups/Groups.js"));
// const ViewGroups = lazy(() => import("../compo/Groups/ViewGroups.js"));
// const ViewShareGroups = lazy(() => import("../compo/Groups/ViewShareGroups.js"));
// const Ftp = lazy(() => import("../compo/ftp.js"));
// // const About = lazy(() => import("../compo/About.js"));
// const AddForm = lazy(() => import("../compo/form.js"));
// const Viewformbook = lazy(() => import("../compo/viewbookfrom.js"));
// const Editformbook = lazy(() => import("../compo/editform.js"));

// /*****Routes******/

// const ThemeRoutes = [
//   {
//     path: "/",
//     element: <Barlayout />,
//     children: [

//       { path: "/", element: <Navigate to="/Signin" /> },
//       { path: "/Signin", exact: true, element: <Signin /> },
//     ]

//   },
//   {
//     element: <FullLayout />,
//     children: [
//       // { path: "/", exact: true, element: <Navigate to="/Signin" /> },
//       { path: "/library", element: <PrivateRoute element={<Library />} /> },
//       { path: "/users", exact: true, element: <PrivateRoute element={<Users />} /> },
//       { path: "/groups", exact: true, element: <PrivateRoute element={<Groups />} /> },
//       { path: "/ftp", exact: true, element: <PrivateRoute element={<Ftp />} /> },
//       { path: "/dashboard", exact: true, element: <PrivateRoute element={<Dashboard />} /> },
//       { path: "/addform", exact: true, element: <PrivateRoute element={<AddForm />} /> },
//       { path: `/editform/:id/`, exact: true, element: <PrivateRoute element={<Editformbook />} /> },
//       { path: `/viewformbook/:id`, exact: true, element: <PrivateRoute element={<Viewformbook />} /> },
//       { path: `/viewgroups/:id`, exact: true, element: <PrivateRoute element={<ViewGroups />} /> },
//       { path: `/viewsharegroups/:id`, exact: true, element: <PrivateRoute element={<ViewShareGroups />} /> },
//     ],
//   },
// ];

// export default ThemeRoutes;

import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import PrivateRoute from "../compo/private.js";
import Dashboard from "../compo/dashboard.js";
/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const Barlayout = lazy(() => import("../layouts/barlayout.js"));

/***** Pages ****/
const Signin = lazy(() => import("../compo/Signin.js"));
const Library = lazy(() => import("../compo/library.js"));
const Users = lazy(() => import("../compo/Users.js"));
const Groups = lazy(() => import("../compo/Groups/Groups.js"));
const ViewGroups = lazy(() => import("../compo/Groups/ViewGroups.js"));
const ViewShareGroups = lazy(() => import("../compo/Groups/ViewShareGroups.js"));
const Ftp = lazy(() => import("../compo/ftp.js"));
const AddForm = lazy(() => import("../compo/form.js"));
const Viewformbook = lazy(() => import("../compo/viewbookfrom.js"));
const Editformbook = lazy(() => import("../compo/editform.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Barlayout />
      </Suspense>
    ),
    children: [
      { path: "/", element: <Navigate to="/signin" /> },
      { path: "/signin", exact: true, element: <Signin /> },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<FullLayout />}>
        <FullLayout />
      </Suspense>
    ),
    children: [
      { path: "/dashboard", exact: true, element: <PrivateRoute element={<Dashboard />} /> },
      { path: "/library", element: <PrivateRoute element={<Library />} /> },
      { path: "/users", exact: true, element: <PrivateRoute element={<Users />} /> },
      { path: "/groups", exact: true, element: <PrivateRoute element={<Groups />} /> },
      { path: "/ftp", exact: true, element: <PrivateRoute element={<Ftp />} /> },
      { path: "/addform", exact: true, element: <PrivateRoute element={<AddForm />} /> },
      { path: "/editform/:id", exact: true, element: <PrivateRoute element={<Editformbook />} /> },
      { path: "/viewformbook/:id", exact: true, element: <PrivateRoute element={<Viewformbook />} /> },
      { path: "/viewgroups/:id", exact: true, element: <PrivateRoute element={<ViewGroups />} /> },
      { path: "/viewsharegroups/:id", exact: true, element: <PrivateRoute element={<ViewShareGroups />} /> },
    ],
  },
];

export default ThemeRoutes;
