import {
    USER_DETAIL_LOAD_FAILURE,
    USER_DETAIL_LOAD_REQUEST,
    USER_DETAIL_LOAD_SUCCESS,
    USER_SAVE_FAILURE,
    USER_SAVE_REQUEST,
    USER_SAVE_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
} from './usersTypes';

const initialState = {
    loading: false,
    data: [],
    errors: [],
    detailLoading: true,
    detailData: null,
    detailErrors: [],
    saveLoading: false,
    saveErrors: [],
    updateLoading: false,
    updateErrors: [],

};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_DETAIL_LOAD_REQUEST:
            return {
                ...state,
                detailErrors: [],
                detailData: null,
                detailLoading: true,
            };
        case USER_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                detailLoading: false,
                detailData: action.payload,
            };
        case USER_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                detailLoading: false,
                detailErrors: action.payload,
            };
        case USER_SAVE_REQUEST:
            return {
                ...state,
                saveErrors: [],
                saveLoading: true,
            };
        case USER_SAVE_SUCCESS:
            return {
                ...state,
                saveLoading: false,
            };
        case USER_SAVE_FAILURE:
            return {
                ...state,
                saveLoading: false,
                saveErrors: action.payload,
            };
        case USER_UPDATE_REQUEST:
            return {
                ...state,
                updateErrors: [],
                updateLoading: true,
            };
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                updateLoading: false,
            };
        case USER_UPDATE_FAILURE:
            return {
                ...state,
                updateLoading: false,
                updateErrors: action.payload,
            };

        default:
            return state;
    }
};

export default userReducer;
